(function () {
  "use strict";
  //const $isotopeGrid = $(".home-fotos-lista").isotope();
  const $isotopeVideos = $(".lista-videos").isotope();
  $(".home-fotos-filtro").on("click", function () {
    $(".home-fotos-filtro").removeClass("active");
    $(this).addClass("active");
    const filter = $(this).data("filter");
    //$isotopeGrid.isotope({ filter: filter });
    $isotopeVideos.isotope({ filter: filter });
  });
})();
